import { authToken, getUserMe } from '@gen2/api/auth/api';
import { getFeatureFlags } from '@gen2/api/feature-flag/api';
import useRouter from '@gen2/hooks/useRouter';
import { IAuthTokenParams } from '@gen2/types/auth';
import { TOrganisation } from '@gen2/types/organisation';
import { TUser } from '@gen2/types/user';
import {
  Client_ID,
  generateOauthCode,
  openRedirectUri,
  Redirect_Uri,
} from '@gen2/utils/auth';
import type { FC, ReactNode } from 'react';
import { createContext, useEffect, useMemo, useReducer } from 'react';

export type TFeatureFlags = {
  custom_domain: boolean;
  contact_groups: boolean;
  invite_contact_group: boolean;
  merge_templates: boolean;
  permission: boolean;
  pspdfkit: boolean;
  load_template_improvement: boolean;
  load_template_in_follow_up: boolean;
  watchers: boolean;
  assignee: boolean;
  read_only_requests: boolean;
  sso: boolean;
};

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: TUser | null;
  permissions: TOrganisation['permissions'];
  featureFlags: TFeatureFlags;
}

export interface AuthContextValue extends State {
  platform: 'JWT';
  authCode: () => Promise<void>;
  getToken: () => Promise<void>;
  logout: (params?: LogoutProps[]) => Promise<void>;
  initialize: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

enum ActionType {
  INITIALIZE = 'INITIALIZE',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

type InitializeAction = {
  type: ActionType.INITIALIZE;
  payload: {
    isAuthenticated: boolean;
    user: TUser | null;
    featureFlags: TFeatureFlags;
  };
};

type LoginAction = {
  type: ActionType.LOGIN;
  payload: {
    user: TUser;
    featureFlags: TFeatureFlags;
  };
};

type LogoutAction = {
  type: ActionType.LOGOUT;
};

interface LogoutProps {
  [name: string]: string | boolean;
}

type Action = InitializeAction | LoginAction | LogoutAction;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Handler = (state: State, action: any) => State;

export const initialFeatureFlags: TFeatureFlags = {
  custom_domain: true,
  contact_groups: true,
  invite_contact_group: true,
  merge_templates: true,
  permission: true,
  pspdfkit: false,
  load_template_improvement: false,
  load_template_in_follow_up: false,
  watchers: true,
  assignee: true,
  read_only_requests: false,
  sso: false,
};

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  permissions: {} as TOrganisation['permissions'],
  featureFlags: initialFeatureFlags,
};

const handlers: Record<ActionType, Handler> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user, featureFlags } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      featureFlags,
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user, featureFlags } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      featureFlags,
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
    featureFlags: initialFeatureFlags,
  }),
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'JWT',
  authCode: () => Promise.resolve(),
  getToken: () => Promise.resolve(),
  initialize: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const router = useRouter();
  const [state, dispatch] = useReducer(reducer, initialState);
  const permissions = useMemo(() => {
    if (!state.user) return {} as TOrganisation['permissions'];

    return state.user?.organisations[0].permissions;
  }, [state.user]);

  const initialize = async () => {
    try {
      const accessToken = globalThis.localStorage.getItem('accessToken');

      if (accessToken) {
        const { data } = await getUserMe();
        const user: TUser = data?.data?.user;

        sessionStorage.setItem('FI-USER-SESSION', JSON.stringify(user));
        sessionStorage.setItem('FI-ORGANISATION-ID', user.organisations[0].id);
        sessionStorage.setItem(
          'FI-ACCOUNT-ID',
          user.organisations[0].accounts[0].id,
        );

        // Fetch feature flags
        const { data: featureFlagsData } = await getFeatureFlags();
        const featureFlags = featureFlagsData.data;

        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: true,
            user,
            featureFlags: featureFlags,
          },
        });
      } else {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
            featureFlags: initialFeatureFlags,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
          featureFlags: initialFeatureFlags,
        },
      });
    }
  };

  // Initialization and injection of user information into the application context
  useEffect(() => {
    initialize();
  }, []);

  const authCode = async (): Promise<void> => {
    try {
      const { state, challenge } = await generateOauthCode();
      openRedirectUri(state, challenge);
    } catch (error) {
      console.error('Oauth error ==>', error);
    }
  };

  const getToken = async () => {
    const currentLocation = new URL(window.location.href);
    const authorizationCode = currentLocation.searchParams.get('code') ?? '';
    const stateFromLocation = currentLocation.searchParams.get('state') ?? '';
    const initialCodeVerifier = sessionStorage.getItem('code_verifier') ?? '';

    if (window.sessionStorage.getItem('state') !== stateFromLocation) {
      throw Error('Probable session hijacking attack!');
    }

    const params = new URLSearchParams();
    const queryParams: IAuthTokenParams = {
      client_id: String(Client_ID),
      grant_type: 'authorization_code',
      code: authorizationCode,
      code_verifier: initialCodeVerifier,
      redirect_uri: Redirect_Uri,
    };

    for (const param in queryParams) {
      params.append(param, queryParams[param as keyof IAuthTokenParams] ?? '');
    }

    const { data } = await authToken(params);
    localStorage.setItem('accessToken', data?.access_token);
    localStorage.setItem('refreshToken', data?.refresh_token);

    const { data: result } = await getUserMe();

    const user: TUser = {
      ...result?.data?.user,
    };

    // set the user's organisations
    sessionStorage.setItem('FI-USER-SESSION', JSON.stringify(user));
    sessionStorage.setItem('FI-ORGANISATION-ID', user.organisations[0].id);
    sessionStorage.setItem(
      'FI-ACCOUNT-ID',
      user.organisations[0].accounts[0].id,
    );

    // Fetch and store feature flags after login
    const { data: featureFlagsData } = await getFeatureFlags();
    const featureFlags = featureFlagsData.data;

    dispatch({
      type: ActionType.LOGIN,
      payload: {
        user,
        featureFlags: featureFlags,
      },
    });

    router.navigate(`/invite-listing`);
  };

  const clearSessionStorage = () => {
    sessionStorage.removeItem('FI-USER-SESSION');
    sessionStorage.removeItem('FI-ORGANISATION-ID');
    sessionStorage.removeItem('FI-ACCOUNT-ID');
  };

  const logout = async (params?: LogoutProps[]) => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    dispatch({ type: ActionType.LOGOUT });
    clearSessionStorage();

    let route = '/login';

    if (params) {
      const queryParams = new URLSearchParams();

      params.forEach((paramVal) => {
        const keys: string[] = Object.keys(paramVal);

        keys.forEach((key) => {
          queryParams.append(key, paramVal[key].toString());
        });
      });

      route = '/login?' + queryParams.toString();
    }

    router.navigate(route);
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        authCode,
        getToken,
        logout,
        initialize,
        permissions,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;
