import { baseType, colors, weights } from '@components/theme/gen2';
import { Box, styled } from '@mui/material';
import { rem } from 'polished';

interface StyledTagsContainerStyleProps {
  isError: boolean;
}

export const StyledTagsContainer = styled('div')<StyledTagsContainerStyleProps>(
  ({ theme, isError }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: rem(10),
    padding: `${rem(23)} ${rem(16)}`,
    background: 'none',
    border: 'none',
    [theme.breakpoints.up('sm')]: {
      padding: `${rem(23)} ${rem(50)}`,
    },
    color: isError ? colors.danger : colors.grayDark3,
  }),
);

export const StyledTagsLineLabel = styled('p')<StyledTagsContainerStyleProps>(
  ({ isError }) => ({
    fontWeight: weights.medium,
    fontFamily: baseType,
    fontSize: rem(14),
    minWidth: rem(118),
    color: isError ? colors.danger : colors.grayDark2,
    userSelect: 'none',
  }),
);

export const StyledBox = styled(Box)({
  display: 'flex',
  gap: rem(10),
});
