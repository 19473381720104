import { ILoginPassword } from '@gen2/api/login/api';
import { useLoginPasswordMutation } from '@gen2/api/login/hooks';
import { useAuth } from '@gen2/hooks';
import { ErrorResponse } from '@gen2/utils/formatMessage';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { AxiosResponse } from 'axios';
import { has } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { EmailIdentifier, Form, Hint } from './Login.styled';

interface PasswordProps {
  active: boolean;
  email: string;
  handleBack: (step?: number) => void;
  handleMfaRequired: () => void;
}

const schema = Yup.object().shape({
  password: Yup.string().required('password.required'),
});

const PasswordForm: FC<PasswordProps> = ({ active, email, handleBack, handleMfaRequired }) => {
  const { t } = useTranslation('validation');

  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [messages, setMessages] = useState<string[]>([]);
  const { mutate } = useLoginPasswordMutation();
  const { authCode } = useAuth();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginPassword>({
    defaultValues: {
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const onSubmit: SubmitHandler<ILoginPassword> = (form) => {
    const newForm = {
      ...form,
      email,
    };
    setIsLoading(true);
    mutate(newForm, {
      onError: (error: unknown) => {
        const axiosError = error as AxiosResponse;
        if (axiosError) {
          const { status, data } = axiosError;
          setIsShow(true);
          if (status === 422) {
            const { errors } = data as ErrorResponse;
            if (errors && has(errors, 'email')) {
              setMessages([t('email.invalid')]);
            }
          }
          if (status === 401 || status === 400) {
            setMessages([t(`error.${status}`, { ns: 'common' })]);
          }
          if (status === 429) {
            setMessages([data.message]);
          }
          if (status >= 500) {
            setMessages([t('error.500', { ns: 'common' })]);
          }
        }
        setIsLoading(false);
      },
      onSuccess: (res: unknown) => {
        const response = res as AxiosResponse;

        // 202 means the user is valid but has MFA enabled
        if (response.status === 202) {
          setIsLoading(false);
          handleMfaRequired();
        } else {
          authCode();
        }
      },
    });
  };

  useEffect(() => {
    if (!active) {
      setIsShow(false);
      setMessages([]);
    }
  }, [active]);
  if (!active || !email) return null;

  return (
    <Form>
      <EmailIdentifier data-cy="email-identifier" onClick={() => handleBack(1)}>
        <span>{email}</span>
      </EmailIdentifier>
      <>
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              id="password"
              fullWidth
              required
              label={t('password.label')}
              error={Boolean(errors.password)}
              type={checked ? 'text' : 'password'}
              helperText={
                errors.password?.message && t(errors.password.message)
              }
              inputProps={{
                'data-cy': 'password-input',
              }}
            />
          )}
        />
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              checked={checked}
              onChange={handleChange}
              inputProps={
                {
                  'data-cy': 'checkbox',
                } as object
              }
            />
          }
          label={t('login.checkbox_label', { ns: 'page' })}
        />
      </>
      {isShow && (
        <Alert data-cy="alert" icon={false} severity="error" color="error">
          {messages}
        </Alert>
      )}
      <Hint data-cy="link">
        <Link to="/forgot-password">
          {' '}
          {t('login.forgot_password', { ns: 'page' })}
        </Link>
      </Hint>
      <LoadingButton
        variant="contained"
        color="primary"
        loading={isLoading}
        type="submit"
        onClick={handleSubmit(onSubmit)}
        data-cy="next"
      >
        {t('next', { ns: 'common' })}
      </LoadingButton>
    </Form>
  );
};
export default PasswordForm;
