import { useGlobalRegionsQuery } from '@gen2/api/global-region/hooks';
import { useAuth } from '@gen2/hooks';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { StyledLogo, StyledWrapper } from './Login.styled';

const SSOCallbackHandler = () => {
  const [searchParams] = useSearchParams();

  const { authCode } = useAuth();

  const { data } = useGlobalRegionsQuery();

  const regions = useMemo(() => {
    return data ?? [];
  }, [data]);

  useEffect(() => {
    if (searchParams.has('region') && regions.length > 0) {
      const region = searchParams.get('region');

      if (region) {
        const result = regions.find((r) => r.name === region);

        if (result) {
          localStorage.setItem('regionEndpoint', result.endpoint);

          authCode();
        }
      }
    }
  }, [searchParams, regions, authCode]);

  return (
    <StyledWrapper>
      <StyledLogo />
    </StyledWrapper>
  );
};

export default SSOCallbackHandler;
