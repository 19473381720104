import {TUser} from "@gen2/types/user";
import { Text } from './current-account.styled';

interface CurrentAccountProps {
  user: TUser | null
}

export const CurrentAccount = ({user} : CurrentAccountProps) => {

  if (!user) {
    return null;
  }

  const _organisationId = sessionStorage.getItem('FI-ORGANISATION-ID');
  const _accountId = sessionStorage.getItem('FI-ACCOUNT-ID');

  if (_organisationId) {
    const organisation = user.organisations.find(
      (org) => org.id === _organisationId,
    );

    if (organisation) {

      if (organisation.accounts.length > 1) {

        if (_accountId) {

          const account = organisation.accounts.find(
            (acc) => acc.id === _accountId,
          );

          if (account && organisation.accounts.length > 1 && user.organisations.length > 1) {
            return (
              <Text>
                <b>Organisation:</b> {organisation.name} - <b>Account:</b> {account.name}
              </Text>
            );
          } else if (account && organisation.accounts.length > 1) {
            return (
              <Text>
                <b>Account:</b> {account.name}
              </Text>
            );
          }

        }
      }
    }
  }

  return null;
};

export default CurrentAccount;
